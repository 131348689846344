<template>
  <App
  ref="app"
    :showHeader="false"
    :showFooterTabBar="true"
    activeName="person"
    class="person"
  >
    <div slot="right">asadf</div>
    <header id="header">
      <div class="userData">
        <div class="avator">
          <img
            src="../../assets/images/icon/icon_avator@2x.png"
            :alt="curUser"
          >
        </div>
        <h4>
          {{curUser}}
        </h4>
        <h4>
          {{curRole}}
        </h4>
      </div>
    </header>
    <Card class="bodyMgt">
      <Cell
        is-link
        title="Role"
        :value="curRole"
        @click="roleListShow = true"
        style="white-space: nowrap;"
      ></Cell>
      <Cell
        title="Version"
        value="V1.0.0"
        style="white-space: nowrap;"
      ></Cell>
    </Card>
      <div
        class="out"
        @click="out"
      >
      Log out
        <!-- <img src="../../assets/images/icon/out@2x.png"> -->
      </div>
    <Popup
      v-model="roleListShow"
      round
      closeable
      position="bottom"
      :style="{ height: '60%' }"
    >
      <Row class="title">
        Roles
      </Row>
      <Cell
        is-link
        :value="$i.orgName"
        :title="$i.roleName"
        v-for="($i, $index) in orgList"
        :key="$index"
        @click="orgChange($i)"
      ></Cell>
    </Popup>
  </App>
</template>

<script>
import Card from './components/card'
import { Cell, Toast, Popup, Row, Dialog } from 'vant'
import { orgRoleList, changeRole, getUserListAll, storeList, getRoleAuthority } from '@/api/system'
import storage from '@/utils/storage'
import 'vant/lib/list/style'
import '@/style/vant-patch.less'
import '@/style/common.less';
export default {
  name: 'person',
  components: {
    Card,
    Cell,
    Popup,
    Row
  },
  data () {
    return {
      curUser: '',
      curRole: '',
      roleListShow: false,
      orgList: []
    }
  },
  methods: {
    // 获取用户组织角色
    async getOrgList () {
      const data = await orgRoleList(this.curUser)
      if (data.code === '000000') {
        this.orgList = data.data
        const cur = this.orgList.filter(e => e.orgCode === this.$storage.get('orgCode') && e.roleCode === this.$storage.get('roleCode'))[0]
        this.curRole = cur.orgName + '-' + cur.roleName
      } else {
        Toast.fail(data.msg)
      }
    },
    // 切换用户组织角色
    async orgChange (e) {
      if (e) {
        /* Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          loadingType: 'spinner',
          overlay: true,
          duration: 0
        }) */
        const item = e
        const uid = this.$storage.get('userId')
        const account = uid + '_' + item.codePath + '.' + item.orgType + '.' + item.orgCode + '_' + item.roleCode
        const data = await changeRole(account)
        if (data.code === '000000') {
          this.$storage.set('account_no', account)
          // 角色Code
          this.$storage.set('roleCode', data.data.roleCode)
          // orgType
          this.$storage.set('orgType', data.data.orgType)
          // orgCode
          this.$storage.set('orgCode', data.data.orgCode)
          this.roleCode = data.data.roleCode
          this.orgCode = data.data.orgCode
          this.curRole = item.orgName + '-' + item.roleName
          // 当切换的角色是超级管理员时调转到系统设置页面
          const roleCode = data.data.roleCode
          if (roleCode === 'RE006') {
            // 出行主管 - 存储当前角色下所有出行顾问
            const userRes = await getUserListAll(data.data.orgCode)
            if (userRes.code === '000000') {
              this.$storage.set('makeEmpList', userRes.data)
            }
          }

          await Promise.all([
            storeList({ orgCode: data.data.orgCode }),
            getRoleAuthority(roleCode)
          ]).then(([storeRes, authRes]) => {
            if (storeRes.success) {
              //切换用户角色完成后重新存储当前角色下的门店
              const saleDlr = storeRes.data
              this.$storage.set('userStore', saleDlr)
            }
            if (authRes.success) {
              const authorized = authRes.data.map(item => item.code)
              this.$storage.set('authorized', authorized)
            }
          })

          this.curUser = this.$storage.get('userId')
          this.roleListShow = false
          Toast.clear()
          Toast.success('Success')
        } else {
          Toast.clear()
          Toast.fail(data.msg)
        }
      }
    },
    // 退出登陆
    out () {
      Dialog.confirm({
        message: 'Logout?'
      }).then(() => {
        storage.clear()
        this.$router.push({ path: '/login' })
      })
    },
    init () {
      this.curUser = this.$storage.get('userId')
      this.getOrgList()
    }
  },
  mounted () {
    this.init()
    console.log(this.$refs['app'].footerHeight + 10 +'px')
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>