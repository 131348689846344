<template>
  <section class="card">
    <hgroup class="card-head">
      <h3>{{ title }}</h3>
    </hgroup>
    <div class="card-container">
      <slot>
        <div
          v-for="(item, index) in dataList"
          :key="index"
          :class="['card-info-item', { 'border': border }]"
          :style="itemStyle"
        >
          <div class="card-info-item-value">
            <template v-if="typeof item.value === 'function'">
              <RenderDom :render="item.value" />
            </template>
            <template v-else>
              {{ item.value }}
            </template>
          </div>
          <span
            class="card-info-item-label"
            :style="{ color: labelColor }"
          >{{ item.label }}</span>
        </div>
      </slot>
    </div>
  </section>
</template>
<script>
import RenderDom from '_c/renderDom'
export default {
  components: {
    RenderDom
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => []
    },
    span: {
      type: Number,
      default: 4
    },
    labelColor: {
      type: String,
      default: '#5F5F6A'
    },
    border: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    itemStyle () {
      return {
        'width': parseFloat(100 / this.span).toFixed(2) + '%'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.card {
  border-radius: 22px;
  background-color: #fff;
  padding: 20px 30px;
  margin-bottom: 16px;

  .card-head {
    font-size: 28px;
    color: #28282b;
    font-weight: bold;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .card-info-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;

      .card-info-item-value {
        font-size: 38px;
        color: #0f0f13;
        font-weight: bold;
        font-family: "PingFang SC";

        .img-wrap {
          width: 56px;
          height: 56px;
          border-radius: 14px;
        }
      }

      .card-info-item-label {
        padding-top: 20px;
        font-size: 24px;
        color: #5f5f6a;
      }

      &.border:not(:last-child)::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -35px;
        width: 1px;
        height: 70px;
        background-color: #e7eaef;
        transform: scaleX(0.5);
      }
    }
  }
}
</style>